import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconArrowLeft, IconEdit, IconPlus } from '@tabler/icons'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

import { Redirect, ViewError } from '@/components/commons'
import { StudentProfileCard } from '@/components/surfaces'
import { StudentsForm } from '@/containers/forms'
import { useFetch, useStudent } from '@/lib/hooks'
import { getUrlString, setSession } from '@/lib/utils'
import { useNav } from '@/providers/NavProvider'
import {
  Channel as ChannelType,
  Site as SiteType,
  StudentChannelProfile as StudentChannelProfileType,
  StudentSiteProfile as StudentSiteProfileType
} from '@/types'

import { MultipleStudentChannelProfilesModal } from './components'

interface Data {
  site?: SiteType
  channel?: ChannelType
}

interface Props {
  initialData?: Data
  modalView?: boolean
  children?: React.ReactNode
}

const useStyles = createStyles((theme, props: any) => ({
  addButton: { width: '100%', minWidth: 200, maxWidth: 300 },
  addCard: {
    height: `calc(100% - ${props.isXs ? 30 : props.isMd ? 40 : 50}px)`,
    marginTop: props.isXs ? 30 : props.isMd ? 40 : 50,
    '&:hover': {
      border: `1px solid ${theme.colors[theme.primaryColor][4]}`,
      backgroundColor: theme.colors[theme.primaryColor][1]
    }
  }
}))

export default function ProfilesView({ initialData, modalView }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()

  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)

  const { classes } = useStyles({ isXs, isMd })

  const { mutate: mutateStudent } = useStudent()
  const { setStudentModalOpen, studentModalOpenFromBuyButton, setStudentModalOpenFromBuyButton } =
    useNav()

  // Constants
  const { site } = router?.query || {}
  const siteSlug = getUrlString(site)

  // States
  const [redirectUrl, setRedirectUrl] = useState('')
  const [multipleStudentChannelProfiles, setMultipleStudentChannelProfiles] =
    useState<StudentSiteProfileType | null>(null)
  const [selectedStudent, setSelectedStudent] = useState<StudentSiteProfileType | null>(null)
  const [showFormStudent, setShowFormStudent] = useState(false)

  // Fetch
  const { data: siteData, error: siteError } = useFetch([siteSlug ? `/${siteSlug}/` : null], {
    fallbackData: initialData?.site
  })
  const isSiteLoading = !siteData && !siteError

  const {
    data: studentsData,
    error: studentsError,
    mutate: studentsDataMutate
  } = useFetch([siteSlug ? `/${siteSlug}/students/` : null, { is_active: true }], {
    errorRetryInterval: 300,
    errorRetryCount: 500
  })
  const isStudentsLoading = !studentsData && !studentsError

  // Constants
  const isSitePrivate = siteData?.privacy === 'private'
  const isBeneficiaryShopper = siteData?.beneficiary === 'shopper'

  const showAddStudent = !!siteData && !isSitePrivate && !isBeneficiaryShopper
  const showEditStudent = !isSitePrivate && !studentModalOpenFromBuyButton

  const isLoading = isSiteLoading || isStudentsLoading
  const error = !!studentsError

  // Actions
  const setStudent = useCallback(
    async (studentUid: string) => {
      setSession('stuid', studentUid)
      await mutateStudent()
    },
    [mutateStudent]
  )

  const handleStudentChannelProfileRedirect = (
    studentChannelProfile: StudentChannelProfileType,
    studentSiteProfileUid?: string
  ) => {
    if (studentSiteProfileUid) setStudent(studentSiteProfileUid)
    const channelSlug = studentChannelProfile?.channelProfile?.channel?.slug
    const storeSlug = studentChannelProfile?.showcase?.store?.slug
    const showcaseSlug = studentChannelProfile?.showcase?.slug
    setRedirectUrl(
      `${channelSlug ? `/${channelSlug}` : ''}${channelSlug && storeSlug ? `/${storeSlug}` : ''}${
        channelSlug && storeSlug && showcaseSlug ? `/${showcaseSlug}` : ''
      }`
    )
    if (modalView) setStudentModalOpen?.(false)
  }

  const handleStudentSiteProfileSelect = (studentSiteProfile: StudentSiteProfileType) => {
    const studentChannelProfilesExists =
      Array.isArray(studentSiteProfile?.studentChannelProfiles) &&
      studentSiteProfile?.studentChannelProfiles.length > 0

    if (!studentChannelProfilesExists) {
      setStudent(studentSiteProfile?.uid)
      setRedirectUrl('/accounts/home')
      if (modalView) setStudentModalOpen?.(false)
    } else {
      const isUniqueStudentChannelProfile = studentSiteProfile?.studentChannelProfiles?.length === 1
      return isUniqueStudentChannelProfile
        ? handleStudentChannelProfileRedirect(
          studentSiteProfile.studentChannelProfiles?.[0],
          studentSiteProfile?.uid
        )
        : setMultipleStudentChannelProfiles(studentSiteProfile)
    }
  }

  const handleStudentSiteProfileSelectFromBuyButton = (
    studentSiteProfile: StudentSiteProfileType
  ) => {
    setStudent(studentSiteProfile?.uid)
    setStudentModalOpenFromBuyButton?.(false)
  }

  const handleCloseFormStudent = () => {
    setSelectedStudent(null)
    setShowFormStudent(false)
  }

  const handleFormStudentsFallback = (studentSiteProfile: StudentSiteProfileType) => {
    return studentModalOpenFromBuyButton
      ? handleStudentSiteProfileSelectFromBuyButton(studentSiteProfile)
      : handleCloseFormStudent()
  }

  // Effects
  useEffect(() => {
    if (!isSitePrivate && studentsData?.results?.length === 0) setShowFormStudent(true)
  }, [isSitePrivate, studentsData])

  // Render
  if (isLoading || error) {
    return <LoadingOverlay visible />
  }

  if (isSitePrivate && studentsData?.results?.length === 0) {
    return (
      <ViewError
        title="Nenhum perfil de aluno"
        message="Ops... Você ainda não possui nenhum perfil de aluno. Entre em contato com a escola para que o perfil seja criado."
        boxStyles={{
          height: 'calc(100% - 100px)',
          width: 'calc(100% - 100px)',
          margin: 'auto'
        }}
      />
    )
  }

  if (redirectUrl) {
    return <Redirect url={redirectUrl} />
  }

  if (showFormStudent) {
    return (
      <Container px="xs" py="lg" size="xl">
        <Button
          variant="outline"
          onClick={handleCloseFormStudent}
          leftIcon={<IconArrowLeft size={16} />}
          color="secondary">
          Voltar
        </Button>
        <Container px="xs" py="lg" size="md">
          <Stack>
            <Title order={isXs ? 4 : isSm ? 3 : 2}>
              {selectedStudent ? 'Editar' : 'Criar novo'} aluno
            </Title>
            <StudentsForm
              student={selectedStudent}
              mutate={studentsDataMutate}
              fallbackAction={handleFormStudentsFallback}
            />
          </Stack>
        </Container>
      </Container>
    )
  }

  return (
    <Container px={modalView ? 'lg' : 'xs'} py="lg" size="xl" fluid={!!modalView}>
      <Title order={isXs ? 4 : isSm ? 3 : 2}>Para quem está comprando?</Title>
      <Text mb="lg" size="lg" color="dimmed">
        Selecione o perfil de compra que deseja utilizar:
      </Text>
      <Group spacing="md" position="center" align="stretch">
        {studentsData.results?.map((studentSiteProfile: StudentSiteProfileType) => (
          <StudentProfileCard
            key={studentSiteProfile.uid}
            avatar={studentSiteProfile.picture}
            padding="xl">
            <Stack justify="space-between" spacing="xs" style={{ height: '100%' }}>
              <Box>
                <Title order={isXs ? 5 : isSm ? 4 : 3} mt="xs" align="center">
                  {studentSiteProfile.firstName}
                  {!!studentSiteProfile.lastName && ` ${studentSiteProfile.lastName}`}
                </Title>
                {studentSiteProfile.isResponsible === true && (
                  <Group position="center" my="xs">
                    <Badge size="lg">Você</Badge>
                  </Group>
                )}
                {!!showEditStudent && studentSiteProfile.isResponsible === false && (
                  <Button
                    size="xs"
                    color="custom"
                    variant="subtle"
                    fullWidth
                    compact
                    onClick={() => {
                      setSelectedStudent(studentSiteProfile)
                      setShowFormStudent(true)
                    }}
                    leftIcon={<IconEdit size={16} />}>
                    Editar
                  </Button>
                )}
              </Box>
              <Button
                fullWidth
                mt="lg"
                compact={isXs}
                onClick={() =>
                  studentModalOpenFromBuyButton
                    ? handleStudentSiteProfileSelectFromBuyButton(studentSiteProfile)
                    : handleStudentSiteProfileSelect(studentSiteProfile)
                }>
                {modalView ? 'Selecionar' : 'Comprar Agora'}
              </Button>
            </Stack>
          </StudentProfileCard>
        ))}
        {showAddStudent && (
          <UnstyledButton onClick={() => setShowFormStudent(true)} className={classes.addButton}>
            <Card withBorder className={classes.addCard}>
              <Stack align="center" justify="center" style={{ height: '100%' }}>
                <ThemeIcon size={isXs ? 60 : isMd ? 80 : 100} color="custom" variant="outline">
                  <IconPlus size={isXs ? 30 : isMd ? 40 : 50} />
                </ThemeIcon>
                <Title order={isXs ? 5 : isSm ? 4 : 3} mt="xs" align="center">
                  Adicionar aluno
                </Title>
              </Stack>
            </Card>
          </UnstyledButton>
        )}
      </Group>
      <MultipleStudentChannelProfilesModal
        data={multipleStudentChannelProfiles}
        onSelect={handleStudentChannelProfileRedirect}
        onHomeRedirect={() => {
          if (multipleStudentChannelProfiles?.uid) setStudent(multipleStudentChannelProfiles.uid)
          setRedirectUrl('/accounts/home')
          if (modalView) setStudentModalOpen?.(false)
        }}
        onClose={() => setMultipleStudentChannelProfiles(null)}
      />
    </Container>
  )
}
