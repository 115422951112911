import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  createStyles,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconChevronRight, IconPhotoOff, IconPlus } from '@tabler/icons'
import React from 'react'

import {
  StudentChannelProfile as StudentChannelProfileType,
  StudentSiteProfile as StudentSiteProfileType
} from '@/types'

interface Props {
  data: StudentSiteProfileType | null
  onSelect: (
    studentChannelProfile: StudentChannelProfileType,
    studentSiteProfileUid: string
  ) => void
  onHomeRedirect: () => void
  onClose: () => void
}

const useStyles = createStyles(theme => ({
  papper: {
    '&:hover': {
      border: `1px solid ${theme.colors[theme.primaryColor][4]}`,
      backgroundColor: theme.colors[theme.primaryColor][1]
    }
  }
}))

export default function MultipleStudentChannelProfilesModal({
  data,
  onSelect,
  onHomeRedirect,
  onClose
}: Props) {
  // Hooks
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  return (
    <Modal
      centered
      size="auto"
      overflow="inside"
      opened={!!data}
      onClose={onClose}
      closeButtonLabel="Fechar"
      title={<Title order={isXs ? 4 : 3}>Qual canal deseja acessar?</Title>}
      zIndex={202}>
      <Stack spacing="xs">
        {data?.studentChannelProfiles?.map((studentChannelProfile: StudentChannelProfileType) => (
          <UnstyledButton
            key={studentChannelProfile.uid}
            onClick={() => onSelect(studentChannelProfile, data?.uid)}>
            <Paper withBorder className={classes.papper}>
              <Group p="lg" spacing="md" noWrap>
                <Avatar
                  src={studentChannelProfile?.channelProfile?.channel?.logo}
                  color="custom"
                  variant="filled"
                  size={isXs ? 'md' : 'lg'}
                  radius="xl">
                  <IconPhotoOff size={isXs ? 20 : 24} />
                </Avatar>
                <Box>
                  <Title order={isXs ? 5 : 4}>
                    {studentChannelProfile?.channelProfile?.channel?.title}
                  </Title>
                  <Text size="xs" color="dimmed">
                    {studentChannelProfile?.showcase?.store?.grade?.parent?.educationalLevel?.name
                      ? `${studentChannelProfile.showcase.store.grade.parent.educationalLevel.name} / `
                      : ''}
                    {studentChannelProfile?.showcase?.store?.displayName
                      ? `${studentChannelProfile.showcase.store.displayName} / `
                      : ''}
                    {studentChannelProfile?.showcase?.displayName
                      ? `${studentChannelProfile.showcase.displayName}`
                      : ''}
                    {studentChannelProfile?.showcase?.cycle
                      ? ` (${studentChannelProfile.showcase.cycle})`
                      : ''}
                  </Text>
                </Box>
                <Group position="right" sx={{ flexGrow: 1 }}>
                  <ActionIcon color="custom" component="div">
                    <IconChevronRight size={isXs ? 20 : 24} />
                  </ActionIcon>
                </Group>
              </Group>
            </Paper>
          </UnstyledButton>
        ))}
        <Button
          variant="light"
          fullWidth
          onClick={onHomeRedirect}
          leftIcon={<IconPlus size={16} />}>
          Comprar em outro canal
        </Button>
      </Stack>
    </Modal>
  )
}
